<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Add Debt for {{ user.name }}</span>
      </v-card-title>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="saveCustomerDebt"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.amount"
                  label="Debt Amount"
                  placeholder="Enter debt amount"
                  :rules="[v => !!v || 'Debt amount is required', v => /^[0-9]+$/.test(v) || 'Must be numeric']"
                  :error="form.errors.has('amount')"
                  :error-messages="form.errors.get('amount')"
                  hint="Enter the numeric debt amount, without commas."
                  outlined
                ></v-text-field>
                <v-textarea
                  v-model="form.description"
                  label="Debt Description"
                  placeholder="e.g Outstanding Debt"
                  rows="1"
                  :error="form.errors.has('description')"
                  :error-messages="form.errors.get('description')"
                  hint="Provide a brief description of the debt."
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :loading="form.busy"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      amount: '',
      description: '',
    }),
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user(value) {
      if (Object.keys(value).length > 0) {
        this.form.reset()
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.reset()
    },
    saveCustomerDebt() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post(`customer/add-debt/${this.user.customer.id}`, {
            amount: this.form.amount,
            description: this.form.description,
          })
          .then(() => {
            this.$toast.success('Debt added successfully')
            this.$emit('debtAdded')
            this.$emit('close')
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
